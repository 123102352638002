import React from 'react';
import './validation.css';

export const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
        '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
}

export const isEmpty = (object) => {
    return Object.keys(object).length === 0;
}

export const Error = props => {
    return (
        <React.Fragment>
            {props ? <span className='errorMessage'>{props.error}</span> : null}
        </React.Fragment>
    )
}

// export const Validate = props => {
//     if (props.fields) {
//         props.fields.map((field, i) => {
//             if (props.input[field]) {
//                 // if (!props.input[field]) {
//                 //     console.log('no field', field)
//                 // } else {
//                 //     console.log('field', field)
//                 // }
//                 console.log(props.input[field])
//             }
//             if (props.email[field]) {
//                 console.log('email', field)
//             }
//             // console.log(props.input[field])
//         })
//     }
// }