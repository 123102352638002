import React, { useEffect, useState } from 'react';
import './validation.css';

export default function Message(props) {
    // const [state, setState] = useState({ error: null, success: null })
    // useEffect(() => {
    //     if (props.success) {
    //         setState({ error: null, success: props.success });
    //         setTimeout(() => {
    //             setState({ error: null, success: null });
    //         }, 4000);
    //     }
    //     if (props.error) {
    //         setState({ success: null, error: props.success });
    //         setTimeout(() => {
    //             setState({ error: null, success: null });
    //         }, 4000);
    //     }
    // }, [props]);

    return (
        <React.Fragment>
            {props.error ?
                <div id="snackbar" className="shown danger"><b> <i className="fa fa-times-circle"></i> {props.error}</b></div>
                : null}
            {props.success ?
                <div id="snackbar" className="shown success"><b> <i className="fa fa-check-circle"></i> {props.success}</b></div>
                : null}
        </React.Fragment>
    )
}
