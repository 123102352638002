import React, { useState, useEffect, useRef } from "react";
import Starts from "./Starts";
import { connect, useDispatch } from "react-redux";
import { customerActions, userActions } from "../_actions";
import { Error, isEmpty } from "../_components/Validation";
import Select from "react-dropdown-select";
import DatePicker from "react-date-picker";
import { Link } from "react-router-dom";
import { bytesToSize, parseDate } from "../_constants/functions";
import { documentActions } from "../_actions";
import Message from "../_components/Message";
import { withRouter } from "react-router-dom";
import { API } from "../_services";
import moment from 'moment';

function EditParentDocument(props) {
  const docId = props.match.params.docId;
  const [state, setState] = useState({
    // startsImage: "",
    changeType: "",
    approverId: "",
    isInternal: "",
    customerId: "",
    effectiveDate: "",
    changeJustification: "",
    changeDescription: "",
    username: "",
    password: "",
    comments: "",
    starts: false,
    startsData: null,
    startsImage: [],
    startsImage2: [],
    errors: {},
    customerList: [],
    approvers: [],
    message: "",
    errormessage: "",
    viewImages: false,
    addNewImage: false,
    productFamilyName: "",
    docId: props.uploadParentDocumentFileId,
    uploadSuccess2: false,
    docLogo: null,
    docLogoName: null,
    newDocLogo: false
  });
  const [value, setValue] = useState({
    modal: false,
    image: null,
    username: "",
    password: "",
    error: false
  });
  let selectedParentDocument = {};
  const fileUploader = useRef(null);
  const docUploader = useRef(null);

  useEffect(() => {
    props.getSingleParentById(props.match.params.docId);
    getDocumentData();
  }, []);

  useEffect(() => {
    getDocumentData();
  }, [props.parent]);

  const getDocumentData = (e) => {
    let selectedParentDocument = props.parent;

    let customerData = props.customerList.items ? props.customerList.items : [];
    let customerList = [];
    if (customerData) {
      customerData.map((v) => {
        customerList.push({ value: v.id, name: v.customerName, label: v.customerName });
      });
    }

    let users = props.users.items;
    let activeAdmin = [];
    if (users && users.length > 0) {
      users.map((v) => {
        if (v.role === "ADMIN" && v.userActive && !v.isDeleted) {
          activeAdmin.push({ value: v.id, name: v.firstName + " " + v.lastName, label: v.email });
        }
      });
    }

    let customers = [];
    let selectedApprover = [];
    let data = {};

    // if (selectedParentDocument && selectedParentDocument.length > 0) {
    //   let customer = selectedParentDocument[0].customerId;
    //   let value = customerList.find((o) => o.value === customer);
    //   if (value) customers.push(value);
    // }
    // if (selectedParentDocument && selectedParentDocument.length > 0 && activeAdmin.length > 0) {
    //   let approverId = selectedParentDocument[0].approverId;
    //   let value = activeAdmin.find((o) => o.value === Number(approverId));
    //   if (value) selectedApprover.push(value);
    // }
    // if (selectedParentDocument && selectedParentDocument.length > 0) {
    //   data = {
    //     approvedDate: selectedParentDocument[0].approvedDate,
    //     approverId: selectedParentDocument[0].approverId,
    //     changeDescription: selectedParentDocument[0].changeDescription,
    //     changeJustification: selectedParentDocument[0].changeJustification,
    //     customerId: selectedParentDocument[0].customerId,
    //     effectiveDate: new Date(selectedParentDocument[0].effectiveDate),
    //     parentDocumentChangeId: selectedParentDocument[0].parentDocumentChangeId,
    //     parentDocumentList: selectedParentDocument[0].parentDocumentList,
    //     isInternal: selectedParentDocument[0].document[0].isInternal,
    //     changeType: selectedParentDocument[0].document[0].changeType,
    //   }
    // }

    if (selectedParentDocument) {
      let customer = selectedParentDocument.customerId;
      let value = customerList.find((o) => o.value === customer);
      if (value) customers.push(value);
    }

    if (selectedParentDocument && activeAdmin.length > 0) {
      let approverId = selectedParentDocument.approverId;
      let value = activeAdmin.find((o) => o.value === Number(approverId));
      if (value) selectedApprover.push(value);
    }

    // data values
    if (selectedParentDocument) {
      data = {
        approvedDate: selectedParentDocument.approvedDate,
        approverId: selectedParentDocument.approverId,
        changeDescription: selectedParentDocument.changeDescription,
        changeJustification: selectedParentDocument.changeJustification,
        customerId: selectedParentDocument.customerId,
        effectiveDate: new Date(selectedParentDocument.effectiveDate),
        parentDocumentChangeId: selectedParentDocument.parentDocumentChangeId,
        parentDocumentList: selectedParentDocument.parentDocumentList,
        isInternal: selectedParentDocument.isInternal,
        changeType: selectedParentDocument.changeType,
        productFamilyName: selectedParentDocument.productFamilyName,
        docLogoName: selectedParentDocument.parentDocumentLogoUrl,
        comments: selectedParentDocument.comments
      };
    }

    // console.log("selectedParentDocument", selectedParentDocument);
    // console.log("selectedParentDocument", selectedParentDocument.parentDocumentList);
    let sParentDocument = selectedParentDocument && selectedParentDocument.parentDocumentList;
    // console.log('selected ', selectedParentDocument);
    setState((state) => ({
      ...state,
      ...data,
      startsData: "",
      approver: selectedApprover,
      customerList: customerList,
      customers: customers,
      approvers: activeAdmin,
      startsImage: sParentDocument,
      viewImages: true
    }));
  };

  useEffect(() => {
    if (
      props.documents.message &&
      props.documents.message !== "Empty" &&
      props.documents.message !== "Document List Fetched Successfully!!"
    ) {
      setState((state) => ({ ...state, message: props.documents.message, errormessage: null }));
      //props.history.push('/document/list');
      setTimeout(() => {
        setState((state) => ({ ...state, message: null, errormessage: null }));
      }, 4000);
    }
  }, [props.documents.message]);

  useEffect(() => {
    if (
      state.docId &&
      props.uploadParentDocumentFileId &&
      props.uploadParentDocumentFileId !== state.docId
    ) {
      setState((state) => ({
        ...state,
        uploadParentDocumentFileId: props.uploadParentDocumentFileId,
        uploadSuccess: true
      }));
      setTimeout(() => {
        setState((state) => ({ ...state, uploadModal: false }));
      }, 1000);
    }
  }, [props.uploadParentDocumentFileId]);

  const changeInput = (e) => {
    e.persist();
    setState((state) => ({ ...state, [e.target.name]: e.target.value }));
  };

  const valueInput = (e) => {
    e.persist();
    setValue((value) => ({ ...value, [e.target.name]: e.target.value }));
  };

  const imageUpload = (e) => {
    // props.refs.fileUploader.click();
    fileUploader.current.click();
  };

  // console.log('here ', state.startsImage2)
  const imageChange = (event) => {
    event.persist();
    // if (!state.addNewImage) {
    //   setState((state) => ({ ...state, addNewImage: true, startsImage: [] }));
    // }
    if (event.target.files.length > 0) {
      setState((state) => ({
        ...state,
        startsImage2: [...state.startsImage2, event.target.files[0]]
      }));
      // setState((state) => ({
      //   ...state,
      //   startsImage: [...state.startsImage, event.target.files[0]]
      // }));
    } else {
      // setState({ startsImage: null, startsImageName: null });
    }
  };

  const changeCheckbox = (event) => {
    event.persist();
    setState((state) => ({ ...state, [event.target.name]: event.target.id }));
  };

  const uploadDocument = (e) => {
    // e.persist();
    props.addDocumentFiles(state.startsImage2, true, state.docLogo);
  };

  useEffect(() => {
    // console.log(props)
    // console.log(state)
    if (props.uploadParentDocumentFileId) {
      setState((state) => ({
        ...state,
        uploadParentDocumentFileId: props.uploadParentDocumentFileId,
        uploadSuccess2: true
      }));
      setTimeout(() => {
        setState((state) => ({ ...state, uploadModal: false }));
      }, 1000);
    }
  }, [props.uploadParentDocumentFileId]);

  const internalCheck = (event) => {
    event.persist();
    setState((state) => ({
      ...state,
      [event.target.name]: event.target.id === "yes" ? true : false
    }));
  };

  const validation = (e) => {
    const {
      startsImage,
      changeType,
      approverId,
      isInternal,
      customerId,
      effectiveDate,
      changeJustification,
      changeDescription,
      username,
      password,
      comments,
      docLogo,
      productFamilyName
    } = state;

    let errors = {};
    if (!changeType) errors["type"] = "Select Type";
    if (!productFamilyName) errors["name"] = "Enter Product Name";
    if (!approverId) errors["approver"] = "Select Approver";
    if (typeof isInternal !== "boolean") errors["internal"] = "Select Internal";
    if (!customerId) errors["customer"] = "Select Customer";
    if (!effectiveDate) errors["effective"] = "Select Effective Date";
    if (!changeDescription) errors["desc"] = "Enter Product Family Description";
    if (!changeJustification) errors["just"] = "Enter Justification of Description";
    if (!username) errors["username"] = "Enter Username";
    if (!password) errors["password"] = "Enter Password";
    if (!comments) errors["comments"] = "Enter Comments";
    // if (!docLogo) errors["docimage"] = "Select Document Logo";
    setState((state) => ({ ...state, errors: errors }));
    return errors;
  };

  const goBack = (e) => {
    props.history.goBack();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEmpty(validation())) {
      //setState(state => ({ ...state, errors: {} }));
      // console.log("here submit");
      let payload = {
        approverId: state.approverId,
        changeJustification: state.changeJustification,
        changeDescription: state.changeDescription,
        customerId: state.customerId,
        effectiveDate: moment(state.effectiveDate).format('DD-MMM-YYYY'),
        changeType: state.changeType,
        isInternal: state.isInternal,
        parentLogo: state.docLogo,
        parentDocuments: state.startsImage2,
        parantChangeId: state.parentDocumentChangeId,
        productFamilyName: state.productFamilyName,
        username: state.username,
        password: state.password,
        comments: state.comments
      };

      props.editDocument(payload, "parent").then((resp) => {
        console.log(resp);
        if (resp.status === 200) {
          setState((state) => ({ ...state, message: resp.data.message }));
          setTimeout(() => {
            setState((state) => ({ ...state, message: null }));
            props.history.goBack();
          }, 1000);
        } else {
          setState((state) => ({ ...state, errormessage: resp.data.message }));
          setTimeout(() => setState((state) => ({ ...state, errormessage: null })), 3000);
        }
      });
    } else {
    }
  };

  const onSelect = (value, name) => {
    setState((state) => ({ ...state, [name]: value[0].value }));
  };

  const onChange = (date, type) => {
    setState((state) => ({ ...state, [type]: date }));
  };

  const removeImages = (e) => {
    setState((state) => ({ ...state, startsImage: [] }));
  };

  const viewImages = (e) => {
    setState((state) => ({ ...state, viewImages: state.viewImages ? false : true }));
  };

  const docUpload = (e) => {
    setState((state) => ({ ...state, docLogoNameProps: null }));
    docUploader.current.click();
  };

  const docChange = (event) => {
    event.persist();
    if (event.target.files.length > 0) {
      let files = [];
      let file = event.target.files[0];
      files.push(event.target.files[0]);
      // props.addDocumentFiles(files, false);
      setState((state) => ({
        ...state,
        docLogo: file,
        docLogoName: file.name,
        newDocLogo: true
      }));
    } else {
      setState((state) => ({
        ...state,
        docLogo: null,
        docLogoName: null
      }));
    }
  };

  const deleteModal = (id) => {
    setValue((value) => ({ ...value, modal: true, image: id }));
  };

  const deleteHandle = (e) => {
    setValue((value) => ({ ...value, error: false }));
    let data = {
      documentParentChangeId: Number(docId),
      fileId: Number(value.image.id),
      password: value.password,
      username: value.username
    };
    console.log(data);
    if (value.username && value.password) {
      API.deleteParentFileById(data).then((resp) => {
        console.log(resp);
        if (resp.status === 200) {
          props.getSingleParentById(docId);
          setState((state) => ({ ...state, message: resp.data.message }));
          setTimeout(() => {
            setState((state) => ({ ...state, message: null }));
            setValue((value) => ({
              ...value,
              image: null,
              modal: false,
              username: "",
              password: ""
            }));
          }, 2000);
        } else {
          setState((state) => ({ ...state, errormessage: resp.data.message }));
          setTimeout(() => {
            setState((state) => ({ ...state, errormessage: null }));
          }, 4000);
        }
      });
    } else {
      setValue((value) => ({ ...value, error: true }));
    }
  };

  const deleteFile = (id) => {
    let pimg = state.startsImage2;
    pimg.splice(id, 1);
    setState((state) => ({ ...state, startsImage2: pimg }));
  };
  // console.log(state.errors)
  return (
    <React.Fragment>
      <Message error={state.errormessage} success={state.message} />
      <div className="row">
        <div className="col-12 grid-margin">
          <div className="card">
            <div className="card-body">
              <form>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Approval/Supporting Document(s)&nbsp;&nbsp;
                        {state.startsImage &&
                          state.startsImage.length < 4 &&
                          !state.uploadSuccess ? (
                            <span
                              onClick={
                                state.startsImage && state.startsImage.length <= 4
                                  ? imageUpload
                                  : null
                              }
                              className="btn btn-sm btn-primary">
                              <i className="mdi mdi-attachment" />
                            </span>
                          ) : null}
                        &nbsp;&nbsp;
                        {state.startsImage &&
                          state.startsImage.length > 0 &&
                          !state.uploadSuccess ? (
                            <React.Fragment>
                              <span onClick={removeImages} className="btn btn-sm btn-danger">
                                {" "}
                                <i className="mdi mdi-close" />
                              </span>
                            &nbsp;&nbsp;
                              <span onClick={viewImages} className="btn btn-sm btn-info">
                                {" "}
                                <i className="mdi mdi-eye" />
                              </span>
                            </React.Fragment>
                          ) : null}
                        {state.uploadSuccess ? (
                          <i
                            className="mdi mdi-checkbox-marked-circle"
                            style={{ color: "green" }}
                          />
                        ) : null}
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          value={
                            state.startsImage && state.startsImage.length > 0
                              ? state.startsImage.length + " Files"
                              : "No Image Selected"
                          }
                          aria-label="Recipient's username"
                          disabled
                          aria-describedby="basic-addon2"
                          id="BtnBrowseHidden"
                        />
                        <input
                          type="file"
                          ref={fileUploader}
                          onChange={imageChange}
                          style={{ display: "none" }}
                        />
                        <div className="input-group-append">
                          <button
                            className={
                              state.startsImage &&
                                state.addNewImage &&
                                state.startsImage.length > 0 &&
                                !state.uploadSuccess
                                ? "btn btn-sm btn-gradient-primary"
                                : "btn btn-sm btn-gradient-secondary"
                            }
                            onClick={
                              state.startsImage &&
                                state.addNewImage &&
                                state.startsImage.length > 0 &&
                                !state.uploadSuccess
                                ? () => setState((state) => ({ ...state, uploadModal: true }))
                                : null
                            }
                            type="button">
                            Upload File
                          </button>
                        </div>
                      </div>
                      {state.viewImages ? (
                        <div className="collapse show" id="collapseExample">
                          <div className="card card-body">
                            <ul className="list-group">
                              {state.startsImage && state.startsImage.length > 0
                                ? state.addNewImage
                                  ? state.startsImage.map((v, i) => {
                                    return (
                                      <li key={i} className="list-group-item">
                                        {++i}. {v.name}{" "}
                                        {state.uploadSuccess2 ? (
                                          <i
                                            className="mdi mdi-checkbox-marked-circle"
                                            style={{ color: "green" }}
                                          />
                                        ) : null}{" "}
                                        <i
                                          className="mdi mdi-delete"
                                          onClick={() => this.deleteFile(i)}
                                          style={{ cursor: "pointer" }}
                                        />
                                      </li>
                                    );
                                  })
                                  : state.startsImage.map((v, i) => {
                                    return (
                                      <li
                                        key={i}
                                        className="list-group-item"
                                        style={{ width: "100%" }}>
                                        <div>
                                          {i++}. <embed src={v.path} height="50px" width="50px" />
                                          {/* <i className="mdi mdi-checkbox-marked-circle" style={{ color: "green" }} /> */}
                                          <i
                                            className="mdi mdi-delete"
                                            onClick={() => deleteModal(v)}
                                            style={{ cursor: "pointer", color: "red" }}
                                          />
                                        </div>
                                        <div>{v.docName}</div>
                                      </li>
                                    );
                                  })
                                : null}
                              {state.startsImage2 && state.startsImage2.length > 0
                                ? state.startsImage2.map((v, i) => {
                                  return (
                                    <li key={i} className="list-group-item">
                                      {++i}. {v.name} - {bytesToSize(v.size)}{" "}
                                      <i
                                        className="mdi mdi-checkbox-marked-circle"
                                        style={{ color: "green" }}
                                      />
                                      <i
                                        className="mdi mdi-delete"
                                        onClick={() => deleteFile(i - 1)}
                                        style={{ cursor: "pointer" }}
                                      />
                                    </li>
                                  );
                                })
                                : null}
                            </ul>
                          </div>
                        </div>
                      ) : null}
                      {/* <Error error={state.errors['image']} /> */}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>
                        Document Logo{" "}
                        {state.docLogo || state.docLogoNameProps ? (
                          <i
                            className="mdi mdi-checkbox-marked-circle"
                            style={{ color: "green" }}
                          />
                        ) : null}
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          value={
                            state.docLogoName
                              ? // ? state.docLogoNameProps
                              // : state.changeImage
                              //   ?
                              state.docLogoName
                              : "No Image Selected"
                          }
                          aria-label="Recipient's username"
                          disabled
                          aria-describedby="basic-addon2"
                          id="BtnBrowseHidden"
                        />
                        <input
                          type="file"
                          ref={docUploader}
                          onChange={docChange}
                          style={{ display: "none" }}
                        />
                        <div className="input-group-append">
                          <button
                            className={"btn btn-sm btn-gradient-primary"}
                            onClick={docUpload}
                            type="button">
                            Upload File
                          </button>
                        </div>
                      </div>
                      <Error error={state.errors["docimage"]} />
                      <br />
                      {state.docLogoName && !state.newDocLogo ? (
                        <img src={state.docLogoName} alt="parent logo" width={50} height={50} />
                      ) : null}
                    </div>
                  </div>

                  {/* <div className="col-12">
                    <button
                      className={
                        state.startsImage2.length > 0 &&
                          state.docLogo &&
                          !state.uploadSuccess
                          ? "btn btn-sm btn-primary"
                          : "btn btn-sm btn-secondary"
                      }
                      onClick={
                        state.startsImage2.length > 0 && !state.uploadSuccess
                          ? () => setState(state => ({ ...state, uploadModal: true }))
                          : null
                      }
                      type="button"
                      style={{ float: "right" }}>
                      Upload All File
                      </button>
                  </div> */}

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Product Name</label>
                      <input
                        type="text"
                        name="productFamilyName"
                        value={state.productFamilyName}
                        onChange={changeInput}
                        className="form-control"
                        placeholder={"Enter Product Name"}
                      />
                      <Error error={state.errors["name"]} />
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row d-flex align-items-center">
                      <div className="col-md-3 col-lg-3">Accessibility</div>
                      <div className="col-md-9 col-lg-9">
                        <div className="form-check d-inline-block mr-4">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="isInternal"
                              id={"no"}
                              checked={!!state.isInternal === false ? true : false}
                              onChange={internalCheck}
                            />{" "}
                            Online <i className="input-helper" />
                            <i className="input-helper" />
                          </label>
                        </div>
                        <div className="form-check d-inline-block mr-4">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="isInternal"
                              id={"yes"}
                              checked={!!state.isInternal === true ? true : false}
                              onChange={internalCheck}
                            />{" "}
                            Offline <i className="input-helper" />
                            <i className="input-helper" />
                          </label>
                        </div>
                      </div>
                      <Error error={state.errors["internal"]} />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row d-flex align-items-center">
                      <div className="col-md-3 col-lg-3">Change Type</div>
                      <div className="col-md-9 col-lg-9">
                        <div className="form-check d-inline-block mr-4">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="changeType"
                              id="General Document"
                              checked={state.changeType === "General Document" ? true : false}
                              onChange={changeCheckbox}
                            />{" "}
                            General Document <i className="input-helper" />
                            <i className="input-helper" />
                          </label>
                        </div>
                        <div className="form-check d-inline-block mr-4">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="changeType"
                              id="Record"
                              checked={state.changeType === "Record" ? true : false}
                              onChange={changeCheckbox}
                            />{" "}
                            Record <i className="input-helper" />
                            <i className="input-helper" />
                          </label>
                        </div>
                        <div className="form-check d-inline-block mr-4">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="changeType"
                              id="Model/Product Information"
                              checked={
                                state.changeType === "Model/Product Information" ? true : false
                              }
                              onChange={changeCheckbox}
                            />{" "}
                            Model/Product Information <i className="input-helper" />
                            <i className="input-helper" />
                          </label>
                        </div>
                        <div className="form-check d-inline-block">
                          <label className="form-check-label">
                            <input
                              type="radio"
                              className="form-check-input"
                              name="changeType"
                              id="Product Drawing"
                              checked={state.changeType === "Product Drawing" ? true : false}
                              onChange={changeCheckbox}
                            />{" "}
                            Product Drawing <i className="input-helper" />
                            <i className="input-helper" />
                          </label>
                        </div>
                      </div>
                      <Error error={state.errors["type"]} />
                    </div>
                  </div>

                  <div className="col-md-6" style={{ marginTop: "12px" }}>
                    <div className="form-group">
                      <label>Customer</label>
                      <Select
                        className="form-control"
                        options={state.customerList}
                        values={state.customers}
                        onChange={(value) => onSelect(value, "customerId")}
                      />
                      <Error error={state.errors["customer"]} />
                    </div>
                  </div>

                  <div className="col-md-6" style={{ marginTop: "12px" }}>
                    <div className="form-group">
                      <label>Approver</label>
                      <Select
                        className="form-control"
                        options={state.approvers}
                        values={state.approver}
                        onChange={(value) => onSelect(value, "approverId")}
                      />
                      <Error error={state.errors["approver"]} />
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Effective Date</label>
                      <DatePicker
                        className="form-control datepickerWidth"
                        name="effectiveDate"
                        onChange={(e) => onChange(e, "effectiveDate")}
                        value={state.effectiveDate}
                        format="dd/MM/y"
                      />
                      <Error error={state.errors["effective"]} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Description of Change</label>
                      <textarea
                        rows={4}
                        name="changeDescription"
                        value={state.changeDescription}
                        onChange={changeInput}
                        className="form-control"
                      />
                      <Error error={state.errors["desc"]} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Justification of Change</label>
                      <textarea
                        rows={4}
                        name="changeJustification"
                        value={state.changeJustification}
                        onChange={changeInput}
                        className="form-control"
                      />
                      <Error error={state.errors["just"]} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Username</label>
                      <input
                        type="text"
                        name="username"
                        value={state.username}
                        onChange={changeInput}
                        className="form-control"
                      />
                      <Error error={state.errors["username"]} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Password</label>
                      <input
                        type="password"
                        name="password"
                        value={state.password}
                        onChange={changeInput}
                        className="form-control"
                      />
                      <Error error={state.errors["password"]} />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Comments</label>
                      <textarea
                        rows={5}
                        name="comments"
                        value={state.comments}
                        onChange={changeInput}
                        className="form-control"
                      />
                      <Error error={state.errors["comments"]} />
                    </div>
                  </div>
                </div>
                <div className="form-group text-center">
                  <div className="row d-flex align-items-center mt-5">
                    <div className="col-md-12 text-center">
                      {/* <Link to='/document/list'> */}
                      <span
                        className="btn btn-gradient-danger"
                        style={{ marginRight: "10px" }}
                        onClick={goBack}>
                        Back
                      </span>
                      {/* </Link> */}
                      <span onClick={handleSubmit} className="btn btn-gradient-primary">
                        Update
                      </span>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {state.uploadModal ? (
        <div className="modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Are you Sure to Upload Documents
                </h5>
                <button
                  type="button"
                  onClick={() => setState((state) => ({ ...state, uploadModal: false }))}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <ul className="list-group">
                  {state.uploadSuccess ? (
                    <p className="text-success">Uploaded Successfully</p>
                  ) : null}
                  {state.startsImage2.length > 0
                    ? state.startsImage2.map((v, i) => {
                      return (
                        <li key={i} className="list-group-item">
                          {++i}. {v.name} - {bytesToSize(v.size)}
                        </li>
                      );
                    })
                    : null}
                </ul>
                <br />
                Parent Logo
                <ul className="list-group">
                  {state.docLogo ? (
                    <li className="list-group-item">
                      {state.docLogoName} - {bytesToSize(state.docLogo.size)}
                    </li>
                  ) : (
                      "No Parent Logo"
                    )}
                </ul>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setState((state) => ({ ...state, uploadModal: false }))}
                  data-dismiss="modal">
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => uploadDocument()}
                  data-dismiss="modal">
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {value.modal ? (
        <div className="modal">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Are you Sure to Delete Document
                </h5>
                <button
                  type="button"
                  onClick={() => setValue((value) => ({ ...value, modal: false, image: null }))}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {/* {value.image ? value.image.path : ''} */}
                {value.image ? <embed src={value.image.path} height="100px" width="400px" /> : ""}
                <br />
                <br />
                <br />
                <br />
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      name="username"
                      value={value.username}
                      onChange={valueInput}
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      name="password"
                      value={value.password}
                      onChange={valueInput}
                      className="form-control"
                    />
                  </div>
                </div>
              </div>
              <Error error={value.error ? "Enter Username and Password" : null} />
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={() => setValue((value) => ({ ...value, modal: false, image: null }))}
                  data-dismiss="modal">
                  Close
                </button>
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={() => deleteHandle()}
                  data-dismiss="modal">
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : null}
    </React.Fragment>
  );
}

const mapStateToProps = (state) => ({
  documents: state.documents,
  customerList: state.customers,
  users: state.users,
  // uploadParentDocumentFileId: state.documents.uploadResponse && state.documents.uploadResponse.uploadParentDocumentFileId,
  uploadParentDocumentFileId:
    state.documents.uploadResponse && state.documents.uploadResponse.uploadParentDocumentFileId,
  parent: state.documents.documentById
});

const mapDispatchToProps = {
  listUsers: userActions.listUsers,
  listCustomers: customerActions.listCustomers,
  editDocument: documentActions.editDocument,
  addDocumentFiles: documentActions.addDocumentFiles,
  getSingleParentById: documentActions.getSingleParentById
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(EditParentDocument));
