import { userConstants } from '../_constants';

let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ? { loggedIn: false, user, loggingIn: false } : {};

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REDIRECT:
      return {
        loggingIn: false,
        isValidUser1: false,
      };
    case userConstants.CHECK_USER_REQUEST:
      return {
        loggingIn: true,
      };
    case userConstants.CHECK_USER_SUCCESS:
      return {
        loggingIn: false,
        isValidUser1: true
      };
    case userConstants.CHECK_USER_FAILURE:
      console.log("Inside Failure reduces");
      return {
        loggingIn: false,
        isValidUser1: false,
        error: action.error
      };
    case userConstants.NOTIFIED_ADMIN_SUCCESS:
      return {
        loggingIn: false,
        isValidUser1: true,
        message: action.message
      };
    case userConstants.NOTIFIED_ADMIN_FAILURE:
      console.log("Inside Failure reduces");
      return {
        loggingIn: false,
        isValidUser1: false,
        message: action.message
      };
    case userConstants.LOGIN_REQUEST:
      return {
        loggingIn: true,
        user: action.user
      };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
    case userConstants.LOGIN_FAILURE:
      return {
        error: action.error
      };
    case userConstants.LOGOUT:
      return {};
    case userConstants.FORGOT_PASSWORD_SUCCESS:
      return {
        forgotPasswordMessage: action.message
      };
    case userConstants.FORGOT_PASSWORD_FAILURE:
      return {
        forgotPasswordError: action.error
      };
    case userConstants.CHANGE_PASSWORD_SUCCESS:
      return {
        ...state, changePasswordMessage: action.message
      };
    case userConstants.CHANGE_PASSWORD_FAILURE:
      return {
        ...state, changePasswordError: action.error
      };
    case userConstants.REMOVE_CHANGE_USER_SUCCESS_MESSAGE:
      return {
        ...state, changePasswordMessage: action.message
      };
    default:
      return state
  }
}