import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { customerActions, documentActions, userActions } from '../_actions';
import Topnavbar from '../_components/Topnav';
import SideNav from '../_components/Sidenav';
import Footer from '../_components/footer';
// import Mainarea from '../_components/Mainpannel';
import Query from '../Allpage/Query';
import Home from '../Allpage/Home';
import Archive from '../Allpage/Archive';
// import Change from '../Allpage/Change';
// import Masschange from '../Allpage/Masschange';
// import Stopchange from '../Allpage/Stopchange';
import { Manageuser } from '../Allpage/Manageuser';
import { ManageCustomer, AddCustomer } from '../CustomerPage';
import ChangePassword from '../_components/ChangePassword';
import DocumentsList from '../Documents/DocumentsList';
import AddDocument from '../Documents/AddDocument';
import EditChildDocument from '../Documents/EditChildDocument';
import EditParentDocument from '../Documents/EditParentDocument';
import DocumentEdit from '../Documents/DocumentEdit';
import DocumentSearch from '../Documents/DocumentSearch';

class HomePage extends React.Component {
    componentDidMount() {
        this.props.listUsers();
        this.props.listCustomers();
        this.props.getDocumentDetails();
        this.props.getParentDocumentsList();
    }
    render() {
        // const { user, users } = this.props;
        return (
            <React.Fragment>
                <Topnavbar />
                <div className="container-fluid page-body-wrapper">
                    <SideNav />
                    <div className="main-panel">
                        <div className="content-wrapper main-bg">
                            <Switch>
                                <Route path='/query' component={Query} />
                                <Route path="/archive" component={Archive} />
                                <Route path="/manageuser" component={Manageuser} />
                                <Route path="/managecustomer" component={ManageCustomer} />
                                <Route path="/addcustomer/:customerId" component={AddCustomer} />
                                <Route path="/document/add" component={AddDocument} />
                                <Route path="/document/search" component={DocumentSearch} />
                                <Route path="/document/editparent/:docId" component={EditParentDocument} />
                                <Route path="/document/editchild/:editId" component={EditChildDocument} />
                                <Route path="/document/:crid/addchild" component={EditChildDocument} />
                                <Route path="/document/list" component={DocumentsList} />
                                <Route path="/document/view/:id" component={DocumentEdit} />
                                <Route path="/archive" component={Archive} />
                                <Route path="/password" component={ChangePassword} />
                                <Route exact path="/" component={Home}> <Redirect to="/document/list" /> </Route>
                            </Switch>
                        </div>
                        <Footer />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

function mapState(state) {
    const { users, authentication } = state;
    const { user } = authentication;
    return { user, users };
}

const actionCreators = {
    listUsers: userActions.listUsers,
    getParentDocumentsList: documentActions.getParentDocumentsList,
    getDocumentDetails: documentActions.getDocumentDetails,
    listCustomers: customerActions.listCustomers,
}

const connectedHomePage = connect(mapState, actionCreators)(HomePage);
export { connectedHomePage as HomePage };