import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { userActions } from '../_actions';
import { connect } from 'react-redux';

class Sidenav extends React.Component {
  state = { menu: [], active: 'home', view: null };
  handleNavbar = e => {
    let { menu } = this.state;
    console.log("clicked", e.target.textContent);
    this.setState({ menu: [e.target.textContent], active: e.target.textContent })

  }
  handleLogout = e => {
    this.props.logout();
  }
  render() {
    let { active, menu, view } = this.state;
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className={active === 'Home' ? "nav-item active font-weight-bold" : "nav-item"}>
            <br /><br />
            <Link className="nav-link" to="/" onClick={this.handleNavbar}>
              <span className="menu-title">Home</span>
              <i className="mdi mdi-home menu-icon"></i>
            </Link>
          </li>
          {/* 
          <li className={active === 'Query' ? "nav-item active font-weight-bold" : "nav-item"}>
            <Link to="/query" id='query' onClick={this.handleNavbar} className="nav-link">
              <span className="menu-title">Query</span>
              <i className="mdi mdi-alert-circle-outline menu-icon"></i>
            </Link>
          </li>

          <li className={active === 'Archive' ? "nav-item active font-weight-bold" : "nav-item"}>
            <Link className="nav-link" to="/archive" onClick={this.handleNavbar}>
              <span className="menu-title">Archive</span>
              <i className="mdi mdi-file-multiple menu-icon"></i>
            </Link>
          </li>
       <li className={active === 'Change' ? "nav-item active font-weight-bold" : "nav-item"}>
            <Link className="nav-link" to="/change" onClick={this.handleNavbar}>
              <span className="menu-title">Change</span>
              <i className="mdi mdi-tooltip-edit menu-icon"></i>
            </Link>
          </li>
          <li className={active === 'Stop Change' ? "nav-item active font-weight-bold" : "nav-item"}>
            <Link className="nav-link" to="/stopchange" onClick={this.handleNavbar}>
              <span className="menu-title">Stop Change</span>
              <i className="mdi mdi-tooltip-edit menu-icon"></i>
            </Link>
          </li>
          <li className={active === 'Mass Change' ? "nav-item active font-weight-bold" : "nav-item"}>
            <Link className="nav-link" to="/masschange" onClick={this.handleNavbar}>
              <span className="menu-title">Mass Change</span>
              <i className="mdi mdi-tooltip-edit menu-icon"></i>
            </Link>
          </li>
          <li className={active === 'Approve Change' ? "nav-item active font-weight-bold" : "nav-item"}>
            <Link className="nav-link" to="/masschange" onClick={this.handleNavbar}>
              <span className="menu-title">Approve Change</span>
              <i className="mdi mdi-tooltip-edit menu-icon"></i>
            </Link>
          </li> */}
          <li className={active === 'Documents' ? "nav-item active font-weight-bold" : "nav-item"}>
            <Link className="nav-link" to="/document/list" onClick={this.handleNavbar}>
              <span className="menu-title">Documents</span>
              <i className="mdi mdi-file-multiple menu-icon"></i>
            </Link>
          </li>
          <li className={active === 'Manage User' ? "nav-item active font-weight-bold" : "nav-item"}>
            <Link className="nav-link" to="/manageuser" onClick={this.handleNavbar}>
              <span className="menu-title">Manage User</span>
              <i className="mdi mdi-account menu-icon"></i>
            </Link>
          </li>
          <li className={active === 'Manage Customer' ? "nav-item active font-weight-bold" : "nav-item"}>
            <Link className="nav-link" to="/managecustomer" onClick={this.handleNavbar}>
              <span className="menu-title">Manage Customer</span>
              <i className="mdi mdi-account-multiple menu-icon "></i>
            </Link>
          </li>
          <li className={active === 'Logout' ? "nav-item active font-weight-bold" : "nav-item"} onClick={this.handleLogout}>
            <span className="nav-link">
              <button className="btn btn-block btn-md btn-gradient-primary mt-4">Logout</button>
            </span>
          </li>
        </ul>
      </nav>
    )
  }
}

const actionCreators = {
  logout: userActions.logout,
};

function mapState(state) {
}

const SidenavPage = connect(null, actionCreators)(Sidenav);
export default SidenavPage;