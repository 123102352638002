import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { store } from "../_helpers";
import { userActions } from "../_actions";
import logo from "../assets/images/shedpaper.jpg";
import { history } from "../_helpers";

import "./login.css";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.logout();
    // console.log("store is", store.getState());
    this.state = {
      username: "",
      password: "",
      isValidUsername: false,
      submitted: false,
      submittedPassword: false,
      showRequest: false,
      isValidPassword: true,
      error: "",
      errorInput: "",
      message: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSubmitPassword = this.handleSubmitPassword.bind(this);
  }

  componentDidMount() {
    // console.log(this.props);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }
    // console.log("Component will update called", this.props);
    if (typeof this.props.isValidUser1 != "undefined") {
      if (!this.props.isValidUser1 !== this.state.showRequest) {
        // console.log("is valid user defined1", (!this.props.isValidUser1 !== this.state.showRequest));
        this.setState({
          isValidUsername: this.props.isValidUser1,
          showRequest: !this.props.isValidUser1
        });
      }
      if (this.props.isValidUser1 != this.state.isValidUsername) {
        // console.log("is valid user defined1", (!this.props.isValidUser1 !== this.state.showRequest));
        this.setState({
          isValidUsername: this.props.isValidUser1,
          showRequest: !this.props.isValidUser1
        });
      }
      // console.log("AuthFailure", this.state.error);
      // console.log("props AuthFailure", this.props.error);
    }
    // if(this.props.forgotPasswordMessage) {
    //   this.setState({message:this.props.forgotPasswordMessage});
    //   // setTimeout(()=>{

    //   // }, 8000);
    // }
  }
  validate(username) {
    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );
    if (!pattern.test(username)) {
      this.setState({ errorInput: "Please enter valid email address." });
      return false;
    } else {
      this.setState({ errorInput: "" });
    }
    return true;
  }

  handleChange(e) {
    const { name, value } = e.target;
    // console.log(name, value)
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { username } = this.state;
    if (username) {
      if (this.validate(username)) {
        // console.log(username)
        this.props.isValidUser(username);
      }

      //this.setState({ isValidUsername: true });
    }
  }

  handleSubmitPassword(e) {
    e.preventDefault();
    this.setState({ submittedPassword: true });
    const { username, password } = this.state;
    if (username && password) {
      this.props.login(username, password);
    }
  }

  handleForgot = (e) => {
    // this redirect after backend response with
    //  this.props.history.push({ pathname: "/forgot_password", state: { email: this.state.username } });
    this.props.forgotPassword(this.state.username);
  };

  render() {
    const { loggingIn, forgotPasswordMessage, forgotPasswordError } = this.props;
    const {
      username,
      password,
      submitted,
      isValidUsername,
      submittedPassword,
      isValidPassword,
      error,
      errorInput
    } = this.state;
    // console.log(isValidUsername)
    return (
      <div className="content-wrapper d-flex align-items-center auth" style={{ height: "100%" }}>
        <div className="row w-100">
          <div className="col-lg-5 col-md-6 mx-auto">
            <div className="auth-form-light text-left p-5">
              <div className="brand-logo">
                <img src={logo} />
              </div>
              {!isValidUsername && (
                <div className="loginCon">
                  <h4 className="login">Login</h4>
                  <h6 className="font-weight-light text-center">
                    Please enter your email or username to login.
                  </h6>
                  <form className="pt-3" name="form" onSubmit={this.handleSubmit}>
                    <div className="form-group mt-4 mb-4">
                      {errorInput && username && <div className="error-msg">{errorInput}</div>}
                      {submitted && !username && (
                        <div className="error-msg">Username is required</div>
                      )}
                      <input
                        type="email"
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        placeholder="Username/Email"
                        name="username"
                        value={username}
                        onChange={this.handleChange}
                        autoFocus />
                    </div>
                    <br />
                    <div className="mt-2">
                      <a
                        className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={this.handleSubmit}>
                        Login
                      </a>
                    </div>
                  </form>
                </div>
              )}
              {isValidUsername && (
                <div className="loginCon">
                  <h4 className="login">Login</h4>
                  {forgotPasswordMessage ? (
                    <h6 className="font-weight-light text-center" style={{ color: "green" }}>
                      {forgotPasswordMessage}
                    </h6>
                  ) : forgotPasswordError ? (
                    <h6 className="font-weight-light text-center" style={{ color: "red" }}>
                      {forgotPasswordMessage}
                    </h6>
                  ) : (
                        <h6 className="font-weight-light text-center">
                          Please enter account password to login
                        </h6>
                      )}
                  <form className="pt-3" name="form1" onSubmit={this.handleSubmitPassword}>
                    <div className="form-group mt-4 mb-4">
                      {submittedPassword && !password && (
                        <div className="error-msg">Password is required</div>
                      )}
                      {submittedPassword && error && !!password && (
                        <div className="error-msg">{error}</div>
                      )}
                      <input
                        type="password"
                        className="form-control form-control-lg"
                        id="exampleInputEmail1"
                        name="password"
                        placeholder="Password"
                        value={password}
                        onChange={this.handleChange}
                        autoFocus
                      />
                    </div>
                    <br />
                    <div className="mt-2">
                      <a
                        className="btn btn-block btn-gradient-primary btn-lg font-weight-medium auth-form-btn"
                        onClick={this.handleSubmitPassword}>
                        Submit
                      </a>
                    </div>
                  </form>
                  <span
                    onClick={() => this.props.isValidUser(null, true)}
                    style={{ float: "left", color: "blue", cursor: "pointer" }}>
                    <i className="mdi mdi-arrow-left-thick"></i> Login
                  </span>
                  <span
                    style={{ float: "right", color: "blue", cursor: "pointer" }}
                    onClick={this.handleForgot}>
                    Forgot Password?
                  </span>
                </div>
              )}
              <br />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapState(state) {
  // console.log('state', state)
  const {
    loggingIn,
    isValidUser1,
    error,
    forgotPasswordMessage,
    forgotPasswordError
  } = state.authentication;
  const { register } = state.registration;
  // console.log("Login Failure", error);
  return { loggingIn, register, isValidUser1, error, forgotPasswordMessage, forgotPasswordError };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
  getToken: userActions.getToken,
  isValidUser: userActions.isValidUser,
  forgotPassword: userActions.forgotPassword
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };
