import React, { useState, useEffect, useRef } from 'react';
import Starts from './Starts';
import ChangeNew from './Change';
import { Preview } from './Preview';
import { connect } from 'react-redux';
import { customerActions, documentActions, userActions } from '../_actions';
import { Link } from 'react-router-dom';
import { Error, isEmpty } from '../_components/Validation';
import Select from 'react-dropdown-select';
import { documents } from '../_reducers/document.reducer';
import { countries } from '../_constants/countries';
import { Languages } from '../_constants/functions';
import Message from '../_components/Message';

function EditChildDocument(props) {
    const [state, setState] = useState({ errors: {}, associatedReferenceNumber: '', changeImage: '', keywords: '', language: '', countryExclusionList: '', productSubFamilyNameOne: '', productSubFamilyNameTwo: '', changeDescription: '', changeJustification: '', username: '', password: '', comments: '', options: countries, languageList: Languages, selectedCountry: [], languages: [], selectedLanguages: [], message: '', errormessage: '', addImage: false, changeImageNameProps: null, });
    const [parent, setParent] = useState(null);
    const [edit, setEdit] = useState(false);
    const fileUploader = useRef(null);

    let selectedChildDocument = {};

    useEffect(() => {
        props.listUsers();
        props.listCustomers();
        if (props.match.params.editId) {
            props.getDocumentDetails(Number(props.match.params.editId));
            setParent(Number(props.match.params.editId));
            console.log('edit')
            setEdit(true)
        } else {
            setParent(Number(props.match.params.crid));
            setEdit(false)
        }
    }, [])

    useEffect(() => {
        if (props.documents.document && edit) {
            let document = props.documents.document;
            let selectedCountry = []; let selectedApprover = [];
            let clist = document.countryList;
            let country = '';
            if (typeof (clist) === 'string') {
                country = clist.split(',');
            } else {
                country = clist;
            }

            if (country) {
                country.map((v) => {
                    if (countries.find((o) => o.value === v)) {
                        selectedCountry.push(countries.find((o) => o.value === v));
                    }
                })
            }

            let langList = [];
            let clist2 = document.languageList;
            let lang = '';
            if (typeof (clist2) === 'string') {
                lang = clist2.split(',');
            } else {
                lang = clist2;
            }
            if (lang) {
                lang.map((v) => {
                    let chk = Languages.find((o) => o.value === v)
                    if (chk) {
                        langList.push(Languages.find((o) => o.value === v));
                    }
                })
            }

            let data = {
                associatedReferenceNumber: document.associatedReferenceNumber,
                productSubFamilyNameOne: document.productSubFamilyNameOne,
                productSubFamilyNameTwo: document.productSubFamilyNameTwo,
                changeDescription: document.changeDescription,
                changeJustification: document.changeJustification,
                keywords: document.keywords,
            }
            setState(state => ({ ...state, ...data, selectedCountry: selectedCountry, languages: clist2, edit: true, selectedLanguages: langList, changeImageNameProps: document.documentPath, comments: document.comments, countryExclusionList:clist}))
        } else {
            setState({ errors: {}, associatedReferenceNumber: '', changeImage: '', keywords: '', language: '', countryExclusionList: '', productSubFamilyNameOne: '', productSubFamilyNameTwo: '', changeDescription: '', changeJustification: '', username: '', password: '', comments: '', options: countries, languageList: Languages, selectedCountry: [], languages: [], changeImageNameProps: null });
        }
    }, [props.documents.document, edit])

    const changeInput = e => {
        e.persist();
        setState(state => ({ ...state, [e.target.name]: e.target.value }));
    }

    const imageUpload = e => {
        setState(state => ({ ...state, changeImageNameProps: null }))
        fileUploader.current.click();
    }

    const imageChange = event => {
        event.persist();
        if (event.target.files.length > 0) {
            let files = [];
            files.push(event.target.files[0]);
            props.addDocumentFiles(files, false);
            setState(state => ({
                ...state,
                changeImage: event.target.files[0],
                changeImageName: event.target.files[0].name
            }));
        } else {
            setState(state => ({
                ...state,
                changeImage: null,
                changeImageName: null
            }));
        }
    }

    const onChange = (date, type) => {
        setState(state => ({
            ...state,
            [type]: date
        }));
    }

    const changeCheckbox = event => {
        setState(state => ({
            ...state,
            [event.target.name]: event.target.id
        }));
    }

    // useEffect(() => {
    //     if (props.documents.message && props.documents.message !== "Empty" && props.documents.message !== "Document Fetched Successfully!!") {
    //         console.log(props.documents.message)
    //         setState(state => ({ ...state, message: props.documents.message, errormessage: null }));
    //         //props.history.push('/document/list');
    //         setTimeout(() => {
    //             setState(state => ({ ...state, message: null, errormessage: null }));
    //         }, 4000);
    //     }
    // }, [props.documents.message]);

    const validation = e => {
        const {
            associatedReferenceNumber,
            changeImage,
            keywords,
            languages,
            countryExclusionList,
            productSubFamilyNameOne,
            productSubFamilyNameTwo,
            changeDescription,
            changeJustification,
            username,
            password,
            comments,
        } = state;

        let errors = {};
        if (!associatedReferenceNumber) errors['ifu'] = 'Enter IFU Number';
        if (!productSubFamilyNameOne) errors['part'] = 'Enter Part Number';
        if (!productSubFamilyNameTwo) errors['bill'] = 'Enter Bill of Material';
        if (!changeDescription) errors['desc'] = 'Enter Change Description';
        if (!changeJustification) errors['just'] = 'Enter Justification';
        if (countryExclusionList.length === 0) errors['country'] = 'Select Country';
        //if (!changeImage) errors['image'] = 'Select Image';
        if (!keywords) errors['keywords'] = 'Enter Keywords';
        if (languages.length === 0) errors['language'] = 'Select Language';
        if (!username) errors['username'] = 'Enter Username';
        if (!password) errors['password'] = 'Enter Password';
        if (!comments) errors['comments'] = 'Enter Comments';
        // if (!online) errors['online'] = 'Select Online';
        // if (!obsoleteDate) errors['obsolete'] = 'Select Obsolete Date';
        setState(state => ({
            ...state,
            errors: errors
        }));
        return errors;
    }

    const handleEditSubmit = (e, action) => {
        let vm = this;
        e.preventDefault();

        if (isEmpty(validation())) {
            let data = {}
            data.associatedReferenceNumber = state.associatedReferenceNumber;
            data.changeDescription = state.changeDescription;
            data.changeJustification = state.changeJustification;
            data.childMainDocument = state.changeImage;
            data.comments = state.comments;
            data.languageList = state.languages;
            data.countryExclusionList = state.countryExclusionList;
            data.countryList = state.countryExclusionList;
            data.keywords = state.keywords;
            data.productFamilyName = state.productFamilyName;
            data.productFamilyDescription = state.productFamilyDescription;
            data.productSubFamilyNameOne = state.productSubFamilyNameOne;
            data.productSubFamilyNameTwo = state.productSubFamilyNameTwo;
            data.username = state.username;
            data.password = state.password || 'Password@1';
            if (action === 'update') {
                data.childDocumentId = props.match.params.editId;
                props.editDocument(data, 'child').then((resp) => {
                    if (resp.status === 200) {
                        setState(state => ({ ...state, message: 'Child Document Updated' }));
                        setTimeout(() => {
                            setState(state => ({ ...state, errormessage: null, message: null }));
                            goBack();
                        }, 3000);
                    } else {
                        setState(state => ({ ...state, errormessage: resp.data.message }));
                        setTimeout(() => {
                            setState(state => ({ ...state, errormessage: null, message: null }));
                        }, 3000);
                    }
                });

            } else {
                data.documentParentChangeId = props.match.params.crid;
                props.addChildToParent(data).then((resp) => {
                    if (resp.status === 200) {
                        setState(state => ({ ...state, message: 'Child Document Added' }));
                        setTimeout(() => {
                            setState(state => ({ ...state, errormessage: null, message: null }));
                            goBack();
                        }, 3000);
                    } else {
                        setState(state => ({ ...state, errormessage: resp.data.message }));
                        setTimeout(() => {
                            setState(state => ({ ...state, errormessage: null, message: null }));
                        }, 3000);
                    }
                    // setState(state => ({ ...state, errormessage: null, message: 'Child Document Added' }));
                    // setTimeout(() => {
                    //     setState(state => ({ ...state, errormessage: null, message: null }));
                    //     goBack();
                    // }, 3000)
                });
            }

        // if (isEmpty(validation())) {
        //     console.log('submit here', state);
        //     // API for Child Document Update


        // } else {

        // }
     }
    }

    const handleAddSubmit = e => {
        // use this parentId
        let parentId = parent;
        props.addChildToParent(state);
        console.log(parentId)
    }

    const onMultiSelect = (selectedList, name) => {
        let list = selectedList.map((country) => {
            return country.value;
        });

        setState(state => ({
            ...state,
            [name]: list
        }));
    }

    const goBack = e => {
        props.history.goBack()
    }

    const refreshPage = e => {
        props.history.go(0);
    }
    return (
        <React.Fragment>
            <Message error={state.errormessage} success={state.message} />
            <div className="row">
                <div className="col-12 grid-margin">
                    <div className="card">
                        <div className="card-body">
                            <form>
                                <div className="row">
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>IFU Number</label>
                                            <input type="text" name='associatedReferenceNumber'
                                                value={state.associatedReferenceNumber}
                                                onChange={changeInput}
                                                className="form-control"
                                                placeholder={'Enter IFU Number'} 
                                                disabled={edit ? true : false}
                                            />
                                            <Error error={state.errors['ifu']} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Part Number</label>
                                            <input type="text" name='productSubFamilyNameOne' 
                                            disabled={edit ? true : false} 
                                            value={state.productSubFamilyNameOne} 
                                            onChange={changeInput} className="form-control"
                                            placeholder={'Enter Part Number'} />
                                            <Error error={state.errors['part']} />
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label>Bill of Material</label>
                                            <input type="text" name='productSubFamilyNameTwo' disabled={edit ? true : false} value={state.productSubFamilyNameTwo} onChange={changeInput} className="form-control" placeholder={'Enter Bill of Material'} />
                                            <Error error={state.errors['bill']} />
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: '12px' }}>
                                        <div className="form-group">
                                            <label>Country</label>
                                            <Select
                                                key={state.selectedCountry}
                                                multi
                                                className="form-control"
                                                options={state.options}
                                                values={state.selectedCountry}
                                                onChange={(values) => onMultiSelect(values, 'countryExclusionList')}
                                            />
                                            <Error error={state.errors['country']} />
                                        </div>
                                    </div>
                                    <div className="col-md-6" style={{ marginTop: '12px' }}>
                                        <div className="form-group">
                                            <label>Language</label>
                                            <Select
                                                key={state.selectedLanguages}
                                                multi
                                                className="form-control"
                                                options={state.languageList}
                                                values={state.selectedLanguages}
                                                onChange={(value) => onMultiSelect(value, 'languages')}
                                            />
                                            <Error error={state.errors['language']} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>keywords</label>
                                            <input type="text" name='keywords' value={state.keywords} onChange={changeInput} className="form-control" />
                                            <Error error={state.errors['keywords']} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Description of Change</label>
                                            <textarea rows={4} name='changeDescription' value={state.changeDescription} onChange={changeInput} className="form-control" />
                                            <Error error={state.errors['desc']} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Justification of Change</label>
                                            <textarea rows={4} name='changeJustification' value={state.changeJustification} onChange={changeInput} className="form-control" />
                                            <Error error={state.errors['just']} />
                                        </div>
                                    </div>

                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Upload doc {state.changeImage || state.changeImageNameProps ? <i className="mdi mdi-checkbox-marked-circle" style={{ color: 'green' }} /> : null}</label>
                                            <div className="input-group">
                                                <input type="text" className="form-control" value={state.changeImageNameProps ? state.changeImageNameProps : state.changeImage ? state.changeImageName : 'No Image Selected'}
                                                    aria-label="Recipient's username" disabled aria-describedby="basic-addon2" id='BtnBrowseHidden' />
                                                <input type="file" ref={fileUploader} onChange={imageChange} style={{ display: 'none' }} />
                                                <div className="input-group-append">
                                                    <button className={"btn btn-sm btn-gradient-primary"} onClick={imageUpload} type="button">Upload File</button>
                                                </div>
                                            </div>
                                            <Error error={state.errors['image']} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Username</label>
                                            <input type="text" name='username' value={state.username} onChange={changeInput} className="form-control" />
                                            <Error error={state.errors['username']} />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Password</label>
                                            <input type="password" name='password' value={state.password} onChange={changeInput} className="form-control" />
                                            <Error error={state.errors['password']} />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label>Comments</label>
                                            <textarea rows={5} name='comments' value={state.comments} onChange={changeInput} className="form-control" />
                                            <Error error={state.errors['comments']} />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group text-center">
                                    <div className="row d-flex align-items-center mt-5">
                                        <div className="col-md-12 text-center">
                                            {/* <Link to={edit ? '/document/list' : '/document/view/' + parent}> */}
                                            <span onClick={goBack} className="btn btn-gradient-danger" style={{ marginRight: '10px' }}>{edit ? 'Back' : 'Back to View'}</span>
                                            {edit ?
                                                <span onClick={(e) => { handleEditSubmit(e, 'update') }} className="btn btn-gradient-primary" style={{ backgroundColor: 'orange' }}>Update</span>
                                                : <span onClick={(e) => { handleEditSubmit(e, 'add') }} className="btn btn-gradient-primary">Add</span>}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </React.Fragment >
    )
}

const mapStateToProps = state => ({
    documents: state.documents
});

const mapDispatchToProps = {
    listUsers: userActions.listUsers,
    listCustomers: customerActions.listCustomers,
    getDocumentDetails: documentActions.getDocumentDetails,
    editDocument: documentActions.editDocument,
    addChildToParent: documentActions.addChildToParent,
    addDocumentFiles: documentActions.addDocumentFiles,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditChildDocument);