import { userConstants } from "../_constants";
import { API } from "../_services";
// import { history, authHeader } from '../_helpers';
// import axios from 'axios';
// import { APIURL } from '../_constants/api.config';

export const documentActions = {
  addDocument,
  editDocument,
  getDocumentDetails,
  getDocumentsList,
  removeMessage,
  addDocumentFiles,
  getParentDocumentsList, //old
  getAllParentsDocuments,
  approveDocument,
  addDocumentListData,
  addChildToParent,
  deleteDocument,
  getSingleParentById
};

function addDocument(documentData) {
  // console.log("Action Add Customer Called");
  return (dispatch) => {
    API.addDocument(documentData).then(
      (document) => {
        // console.log('this is document ', document)
        dispatch(success("Document Added Successfully!!", document));
        //  listCustomers();
        //  history.push('/managecustomer');
      },
      (error) => {
        dispatch(failure("Failed to Add Document!!"));
      }
    );
  };

  function success(message, document) {
    return {
      type: userConstants.CHECK_ADD_DOCUMENT_SUCCESS,
      message,
      document
    };
  }

  function failure(error) {
    return {
      type: userConstants.CHECK_ADD_DOCUMENT_FAILURE,
      error
    };
  }
}

function editDocument(documentData, type) {
  return (dispatch) => {
    let updateDoc;
    if (type === "child") {
      updateDoc = API.editDocument(documentData);
    } else {
      updateDoc = API.editParentDocument(documentData);
    }
    return updateDoc
      .then(
        (document) => {
          console.log("edit", document);
          if (document.status === 200) {
            dispatch(success("Document Updated", document));
          } else {
            dispatch(failure(document.data.message));
          }
          return document;
        },
        (error) => {
          dispatch(failure("Failed to Edit Document!!"));
        }
      )
      .catch((err) => {});
  };

  function success(message, document) {
    return {
      type: userConstants.CHECK_ADD_DOCUMENT_SUCCESS,
      message,
      document
    };
  }

  function failure(error) {
    return {
      type: userConstants.CHECK_ADD_DOCUMENT_FAILURE,
      error
    };
  }
}

function getDocumentDetails(documentId) {
  // console.log("Action getDocumentDetails Called");
  if (documentId) {
    return (dispatch) => {
      API.getDocumentDetails(documentId).then(
        (document) => {
          // console.log(document)
          dispatch(success("Document Fetched Successfully!!", document.data));
        },
        (error) => {
          dispatch(failure("Failed to fetch Document!!"));
        }
      );
    };
  } else {
    return (dispatch) => dispatch(success("Empty", null));
  }

  function success(message, document) {
    return {
      type: userConstants.CHECK_DOCUMENT_DETAILS_SUCCESS,
      message,
      document
    };
  }
  function failure(error) {
    return {
      type: userConstants.CHECK_DOCUMENT_DETAILS_FAILURE,
      error
    };
  }
}

function getDocumentsList() {
  // console.log("Action Add Customer Called");
  return (dispatch) => {
    API.getDocumentsList().then(
      (documents) => {
        dispatch(success("Document List Fetched Successfully!!", documents));
      },
      (error) => {
        dispatch(failure("Failed to get list of documents!!"));
      }
    );
  };

  function success(message, documents) {
    return {
      type: userConstants.CHECK_LIST_DOCUMENTS_SUCCESS,
      message,
      documents
    };
  }

  function failure(error) {
    return {
      type: userConstants.CHECK_LIST_DOCUMENTS_FAILURE,
      error
    };
  }
}

function getParentDocumentsList() {
  // console.log("Action Add Customer Called");
  return (dispatch) => {
    API.getParentDocumentsList().then(
      (documents) => {
        dispatch(success("Document List Fetched Successfully!!", documents));
      },
      (error) => {
        dispatch(failure("Failed to get list of documents!!"));
      }
    );
  };

  function success(message, documents) {
    return {
      type: userConstants.CHECK_LIST_PARENT_DOCUMENTS_SUCCESS,
      message,
      documents
    };
  }

  function failure(error) {
    return {
      type: userConstants.CHECK_LIST_PARENT_DOCUMENTS_FAILURE,
      error
    };
  }
}
function getAllParentsDocuments() {
  return (dispatch) => {
    API.getParentDocumentsList().then(
      (documents) => {
        console.log(documents);
        dispatch(success("Document List Fetched Successfully!!", documents));
      },
      (error) => {
        dispatch(failure("Failed to get list of documents!!"));
      }
    );
  };
  function success(message, documents) {
    return {
      type: userConstants.CHECK_LIST_PARENT_DOCUMENTS_SUCCESS,
      message,
      documents
    };
  }
  function failure(error) {
    return {
      type: userConstants.CHECK_LIST_PARENT_DOCUMENTS_FAILURE,
      error
    };
  }
}

function removeMessage(message) {
  return (dispatch) => {
    dispatch(remove(message));
  };

  function remove(message) {
    return {
      type: userConstants.DOCUMENT_REMOVE_ADD_MESSAGE,
      message
    };
  }
}

// function addDocumentFiles(documentList, username, password, approverId, customerId) {
//     console.log("Action Add Document Files Called");
//     return dispatch => {
//         API.addDocumentFiles(documentList, username, password, approverId, customerId)
//             .then(
//                 message => {
//                     console.log('Document Upload : ', message)
//                     if (message.status === 200) {
//                         dispatch(success('Document Uploaded Successfully !!'));
//                     } else {
//                         dispatch(failure(message.data.message))
//                     }
//                 },
//                 error => {
//                     dispatch(failure('Failed to Upload Document!!'));
//                 }
//             );
//     };

//     function success(message) {
//         return {
//             type: userConstants.CHECK_ADD_DOCUMENTS_SUCCESS,
//             message
//         }
//     }

//     function failure(error) {
//         return {
//             type: userConstants.CHECK_ADD_DOCUMENTS_FAILURE,
//             error
//         }
//     }

// }

function approveDocument(data) {
  return (dispatch) => {
    API.approveDocument(data)
      .then(
        (message) => {
          // console.log(message)
          dispatch(success(message.message));
        },
        (error) => {
          dispatch(failure("Document approval failed."));
        }
      )
      .catch((err) => {
        // console.log('error here', err)
      });
  };

  function success(message) {
    return {
      type: userConstants.APPROVE_DOCUMENT_SUCCESS,
      message
    };
  }

  function failure(message) {
    return {
      type: userConstants.APPROVE_DOCUMENT_FAILURE,
      message
    };
  }
}

function addDocumentFiles(documentsToUpload, isParent, parentLogo) {
  console.log("Action Add Document Files Called");
  return (dispatch) => {
    API.addDocumentFiles(documentsToUpload, isParent, parentLogo).then(
      (response) => {
        console.log("Document Upload : ", response);
        if (response.status === 200) {
          dispatch(success("Document Uploaded Successfully !!", response.data));
        } else {
          dispatch(failure(response.data.message));
        }
      },
      (error) => {
        dispatch(failure("Failed to Upload Document!!"));
      }
    );
  };

  function success(message, uploadResponse) {
    return {
      type: userConstants.CHECK_UPLOAD_DOCUMENTS_SUCCESS,
      message,
      uploadResponse
    };
  }

  function failure(error) {
    return {
      type: userConstants.CHECK_UPLOAD_DOCUMENTS_FAILURE,
      error
    };
  }
}

function addDocumentListData(data) {
  // console.log("Action Add Document list data called");
  return (dispatch) => {
    API.addDocumentListData(data).then(
      (response) => {
        console.log("Document list Upload : ", response);
        if (response.status === 200) {
          dispatch(success("Document Created Successfully !!", response.data));
        } else {
          dispatch(failure(response.data.message));
        }
      },
      (error) => {
        dispatch(failure("Failed to Create Document!!"));
      }
    );
  };

  function success(message, addResponse) {
    return {
      type: userConstants.CHECK_ADD_DOCUMENTS_SUCCESS,
      message,
      addResponse
    };
  }

  function failure(error) {
    return {
      type: userConstants.CHECK_ADD_DOCUMENTS_FAILURE,
      error
    };
  }
}

function deleteDocument(type, id, username, password) {
  return (dispatch) => {
    // let deleteDoc;
    // let successMessage;
    // let errorMessage;
    if (type === "child") {
      return API.deleteChildDoc(id, username, password).then((resp) => {
        // if (resp.status === 200) {
        //     dispatch(success(`Document(${id}) deleted successfully`, resp));
        //     history.push('/document/list');
        // } else {
        //     // dispatch(failure(`Failed to delete document($id)`));
        // }
        return resp;
      });
      // successMessage = `Document(${id}) deleted successfully`;
      // errorMessage = `Failed to delete document($id)`;
    } else {
      return API.deleteParentChange(id, username, password).then((resp) => {
        // if (resp.status === 200) {
        //     dispatch(success(`Document(${id}) deleted successfully`, resp));
        //     history.push('/document/list');
        // } else {
        //     dispatch(failure(`Failed to delete document($id)`));
        // }
        return resp;
      });
      // successMessage = `Parent change request (${id}) deleted successfully`;
      // errorMessage = `Failed to delete parent change request($id)`;
    }
    // return deleteDoc.then(
    //     document => {
    //         console.log('deleteDoc', document)
    //         if (document.status === 200) {
    //             dispatch(success(successMessage, document));
    //             history.push('/document/list');
    //         } else {
    //             dispatch(failure(errorMessage));
    //         }
    //     },
    //     error => {
    //         dispatch(failure(errorMessage));
    //     }
    // ).catch(err => {

    // })
  };

  function success(message, document) {
    return {
      type: userConstants.CHECK_DELETE_DOCUMENT_SUCCESS,
      message,
      document
    };
  }

  function failure(error) {
    return {
      type: userConstants.CHECK_DELETE_DOCUMENT_FAILURE,
      error
    };
  }
}

function addChildToParent(documentData) {
  return (dispatch) => {
    return API.addChildToParent(documentData)
      .then(
        (document) => {
          console.log("edit", document);
          return document;
          if (document.status === 200) {
            dispatch(success(`New document ${document.id} added successfully`, document));
          } else {
            dispatch(failure("Failed to add new document!!"));
          }
        },
        (error) => {
          dispatch(failure("Failed to add new document!!"));
        }
      )
      .catch((err) => {});
  };

  function success(message, document) {
    return {
      type: userConstants.CHECK_ADD_DOCUMENT_SUCCESS,
      message,
      document
    };
  }

  function failure(error) {
    return {
      type: userConstants.CHECK_ADD_DOCUMENT_FAILURE,
      error
    };
  }
}

function getSingleParentById(parantChangeId) {
  return (dispatch) => {
    return API.getSingleParentById(parantChangeId)
      .then(
        (result) => {
          // console.log('id ', result);
          if (result.status === 200) {
            dispatch(success("", result.data));
          } else {
            dispatch(failure("Failed"));
          }
          return result.data;
        }
        // error => {
        //     console.log('Failed to fetch data!!');
        // }
      )
      .catch((err) => {});
  };

  function success(message, document) {
    return {
      type: userConstants.DOCUMENT_PARENT_BY_ID_SUCCESS,
      message,
      document
    };
  }

  function failure(error) {
    return {
      type: userConstants.DOCUMENT_PARENT_BY_ID_ERROR,
      error
    };
  }
}
