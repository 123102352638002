import React, { Component } from "react";
import { API } from "../_services";
import logo from "../assets/images/logo.jpg";

export class CustomerDetailsModal extends React.Component {
  constructor(props) {
    super();
    this.state = {
      customerName: props.companyData.customerName,
      parentChangeId: props.companyData.parentChangeId,
      email: "",
      emailSentMessage: "",
      logo: ""
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleSubmit(e) {
    e.preventDefault();
    //this.setState({ emailSentMessage: 'Please login to ur email id.' });
    API.sendSearchURLLink(this.state.customerName, this.state.email, this.state.parentChangeId)
      .then((response) => {
        if (response.status === 200) {
          this.setState({ emailSentMessage: response.data.message });
        }
      })
      .catch((err) => { });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ email: value });
  }

  render() {
    return (
      <div className="modal fade show">
        <div className="modal_inner">
          <span
            className="mdi mdi-close-circle float-right modal-close"
            style={{ cursor: "pointer", paddingTop: "10px", fontSize: "20px" }}
            onClick={this.props.closePopup}></span>
          <div className="content">
            <div className="container text-center">
              {this.state.emailSentMessage && (
                <div style={{ color: "green", marginBottom: "15px" }}>
                  {this.state.emailSentMessage}
                </div>
              )}
              <h1 style={{ marginTop: "15px" }}>Please enter email id to proceed for search.</h1>
              <div className="row d-flex justify-content-center" style={{ margin: "10px" }}>
                <div className="col-md-8">
                  <form>
                    <div className="form-group">
                      <input
                        type="text"
                        style={{ marginBottom: "20px" }}
                        className="form-control form-control-lg"
                        id=""
                        placeholder="Email"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                      />
                      <a
                        className={
                          "btn btn-block btn-gradient-primary btn-md fit-content font-weight-medium auth-form-btn m-auto"
                        }
                        onClick={this.handleSubmit}>
                        {" "}
                        SUBMIT
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
