import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { customerActions } from "../_actions";
import CustomerView from "./CustomerView";
import { history } from "../_helpers";
import Message from "../_components/Message";
import "../assets/css/modal.css";
import "../assets/css/dataTable.css";
import CustomerApprove from "./CustomerApprove";
import Table from "../_components/Table";
import { countries } from "../_constants/countries";

class CustomerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPopup: false,
      message: "",
      error: "",
      customerViewData: {},
      customers: {
        customerRoutingNumber: "",
        contactEmail: "",
        contactInformation: "",
        countryExclusionList: "",
        customerName: "",
        customerWebsiteLink: "",
        approverId: "",
        id: ""
      },
      approveData: {},
      approveModal: false,
      users: [],
      copied: false
    };

    this.updateUser = this.updateUser.bind(this);
    this.togglePopup = this.togglePopup.bind(this);
  }

  componentDidMount() {
    // console.log(this.props);
    // console.log("component did mount called");
    this.props.listCustomers();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.customerList !== this.props.customerList) {
      this.setState({
        message: this.props.customerList.message,
        error: this.props.customerList.error
      });
      this.setState({ users: this.props.users });
      setTimeout(() => {
        let newObj = {};
        newObj.message = "";
        newObj.error = "";
        this.setState(newObj);
        if (this.props.customerList.message ? true : false) {
          this.props.removeSelectedCustomer();
          this.props.removeMessage();
        }
      }, 2000);
    }
  }

  updateUser(customerData) {
    // console.log(customerData)
    this.props.addSelectedCustomer(customerData);
    history.push("/addcustomer/update");
  }

  togglePopup(customerData) {
    this.setState({
      showPopup: !this.state.showPopup,
      customerViewData: customerData
    });
  }

  approveModal(customerData) {
    this.setState({
      approveModal: true,
      approveData: customerData
    });
    // console.log(customerData)
  }

  cleanUpdate = () => {
    this.props.addSelectedCustomer({});
  };

  onComplete = (e) => {
    this.setState({ approveModal: false, approveId: null });
    this.props.listCustomers();
  };

  copyToClipboard = (data) => {
    this.setState({ message: false });

    var input = document.createElement("input");
    input.setAttribute("value", data.searchUrl);
    document.body.appendChild(input);
    input.select();
    document.execCommand("copy");
    // document.body.removeChild(input);
    // return result;

    // navigator.clipboard.writeText(data.searchUrl)
    // this.setState({ message: 'URL Copied' });
    setTimeout(() => this.setState({ message: "URL Copied" }), 30);
    setTimeout(() => this.setState({ message: false }), 3000);
  };

  render() {
    var country = {}
    if (countries) {
      countries.map((v) => {
        country[v.value] = v.label
      });
    }

    const customers = this.props.customerList.items || [];
    const message = this.state.message;
    const error = "";
    const Header = [
      { headerName: "No", field: "no", resizable: true },
      { headerName: "Name", field: "customerName", resizable: true },
      { headerName: "E-Mail", field: "contactEmail", resizable: true },
      { headerName: "Country", field: "country", resizable: true },
      {
        headerName: "Status",
        field: 'status',
        cellRendererFramework: (params) => {
          let msg, color;
          var approve = 1;
          if (params.node.data.status == "REJECTED") {
            msg = "Rejected";
            color = "danger";
          } else if (params.node.data.status == "APPROVED") {
            msg = "Approved";
            color = "success";
            approve = 0;
          } else if (params.node.data.status == "REQUIRE_APPROVAL") {
            msg = "Approval Pending";
            color = "dark";
          } else {
            msg = "Not Listed";
            color = "light";
          }
          return (
            <React.Fragment>
              <span className={"badge badge-" + color}>{msg}</span>{" "}
            </React.Fragment>
          );
        }
      },
      {
        headerName: "ACTION",
        cellRendererFramework: (params) => {
          return (
            <React.Fragment>
              <span
                className="mdi mdi-eye"
                onClick={() => this.togglePopup(params.node.data.customer)}
                style={{
                  fontSize: "18px",
                  color: "#57c7d4",
                  cursor: "pointer",
                  marginRight: "5px"
                }}></span>
              <span
                className="mdi mdi-pencil"
                onClick={() => this.updateUser(params.node.data.customer)}
                style={{ fontSize: "18px", color: "#198ae3", cursor: "pointer" }}></span>
              {params.node.data.status != "APPROVED" && params.node.data.isValid && (
                <span
                  className="mdi mdi-account-key"
                  onClick={() => this.approveModal(params.node.data.customer)}
                  style={{
                    fontSize: "18px",
                    color: "#ef6c00",
                    cursor: "pointer",
                    marginRight: "5px"
                  }}></span>
              )}
              {params.node.data.status == "APPROVED" && (
                <span
                  className="mdi mdi-file"
                  onClick={() => this.copyToClipboard(params.node.data.customer)}
                  style={{
                    fontSize: "18px",
                    color: "#57c7d4",
                    cursor: "pointer",
                    marginRight: "5px"
                  }}></span>
              )}
            </React.Fragment>
          );
        }
      }
    ];

    let customersList = [];
    typeof customers === "object" &&
      customers &&
      customers.length > 0 &&
      customers.map((value, i) => {
        let userEmail = this.state.users.find((o) => {
          return o.id === Number(value.approverId);
        });
        let cnt = value.countryExclusionList.split(",")
        let ctr = ""
        if (cnt.length > 1) {
          ctr = cnt.map((v) => { return ctr + country[v] + " " });
        } else {
          ctr = country[value.countryExclusionList];
        }
        customersList.push({
          no: ++i,
          customerName: value.customerName,
          contactEmail: value.contactEmail,
          country: ctr,
          status: value.status,
          customer: value,
          isValid: userEmail && userEmail.email === this.props.auth ? true : false
        });
      });

    return (
      <div className="container-fluid">

        <Message error={error} success={message} />

        {this.state.showPopup ? (
          <CustomerView
            title={"View Customer"}
            closePopup={this.togglePopup.bind(this)}
            customerData={this.state.customerViewData}
          />
        ) : null}
        {this.state.approveModal && (
          <CustomerApprove
            onClose={() => this.setState({ approveModal: false, approveData: {} })}
            data={this.state.approveData}
            title={"Customer Approve"}
            onComplete={() => this.onComplete()}
          />
        )}
        <div className="content-wrapper main-bg"></div>
        <div className="page-header">
          <div className="row w-100">
            <div className="col-md-6">
              <h3 className="page-title">
                <span className="page-title-icon bg-gradient-primary text-white mr-2">
                  <i className="mdi mdi-file-multiple"></i>
                </span>
                Customer List
              </h3>
            </div>
            <div className="col-md-6">
              <Link
                to="/addcustomer/create"
                onClick={this.cleanUpdate}
                className="btn btn-sm btn-info float-right ml-auto">
                <i className="mdi mdi-plus-circle"></i> Add Customer
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 grid-margin">
            <div className="card">
              <div className="card-body">
                {/* <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th> Approval Routing Number</th>
                        <th> Name </th>
                        <th> Email </th>
                        <th> Action </th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        customers.map((customer, i) =>
                          <tr key={i}>
                            <td>{customer.customerRoutingNumber}</td>
                            <td>{customer.customerName}</td>
                            <td>{customer.contactEmail}</td>
                            <td>
                              <span className="mdi mdi-account-key" onClick={() => this.approveModal(customer)} style={{ fontSize: '18px', color: '#ef6c00', cursor: 'pointer', marginRight: '5px' }}></span>
                              <span className="mdi mdi-eye" onClick={() => this.togglePopup(customer)} style={{ fontSize: '18px', color: '#57c7d4', cursor: 'pointer', marginRight: '5px' }}></span>
                              <span className="mdi mdi-pencil" onClick={() => this.updateUser(customer)} style={{ fontSize: '18px', color: '#198ae3', cursor: 'pointer' }}></span>

                            </td>
                          </tr>
                        )
                      }
                    </tbody>
                  </table>
                </div> */}
                <Table headers={Header} data={customersList} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  // customers: state.items,
  // message: state.message,
  // error: state.error
  const customerList = state.customers;
  const users = state.users.items;
  const auth = state.authentication.user;
  // console.log('state', state.authentication)
  // const { loggingIn, isValidUser1, error } = state.authentication;
  // const { register } = state.registration;
  // console.log("Login Failure", error);
  return { customerList, users, auth };
}

const actionCreators = {
  listCustomers: customerActions.listCustomers,
  addSelectedCustomer: customerActions.addSelectedCustomer,
  removeSelectedCustomer: customerActions.removeSelectedCustomer,
  removeMessage: customerActions.removeMessage
};

const connectedApp = connect(mapStateToProps, actionCreators)(CustomerList);
export { connectedApp as CustomerList };
