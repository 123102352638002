export const userConstants = {
    REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
    REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
    REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

    FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
    FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

    CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
    CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',
    REMOVE_CHANGE_USER_SUCCESS_MESSAGE: 'REMOVE_CHANGE_USER_SUCCESS_MESSAGE',

    SET_PASSWORD_SUCCESS: 'SET_PASSWORD_SUCCESS',
    SET_PASSWORD_FAILURE: 'SET_PASSWORD_FAILURE',

    LOGOUT: 'USERS_LOGOUT',

    GETALL_REQUEST: 'USERS_GETALL_REQUEST',
    GETALL_SUCCESS: 'USERS_GETALL_SUCCESS',
    GETALL_FAILURE: 'USERS_GETALL_FAILURE',

    DELETE_REQUEST: 'USERS_DELETE_REQUEST',
    DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
    DELETE_FAILURE: 'USERS_DELETE_FAILURE',

    VALID_USER_REQUEST: 'USERS_VALID_USER_REQUEST',
    VALID_USER_SUCCESS: 'USERS_VALID_USER_SUCCESS',
    VALID_USER_FAILURE: 'USERS_VALID_USER_FAILURE',

    CHECK_USER_REQUEST: 'USERS_CHECK_USER_REQUEST',
    CHECK_USER_SUCCESS: 'USERS_CHECK_USER_SUCCESS',
    CHECK_USER_FAILURE: 'USERS_CHECK_USER_FAILURE',
    LOGIN_REDIRECT: 'LOGIN_REDIRECT',

    CHECK_ADD_USER_SUCCESS: 'USERS_CHECK_ADD_USER_SUCCESS',
    CHECK_ADD_USER_FAILURE: 'USERS_CHECK_ADD_USER_FAILURE',

    CHECK_LIST_USER_SUCCESS: 'USERS_CHECK_LIST_USER_SUCCESS',
    CHECK_LIST_USER_FAILURE: 'USERS_CHECK_LIST_USER_FAILURE',

    CHECK_UPDATE_USER_SUCCESS: 'USERS_CHECK_UPDATE_USER_SUCCESS',
    CHECK_UPDATE_USER_FAILURE: 'USERS_CHECK_UPDATE_USER_FAILURE',

    CHECK_DELETE_USER_SUCCESS: 'USERS_CHECK_DELETE_USER_SUCCESS',
    CHECK_DELETE_USER_FAILURE: 'USERS_CHECK_DELETE_USER_FAILURE',

    NOTIFIED_ADMIN_SUCCESS: 'NOTIFIED_ADMIN_SUCCESS',
    NOTIFIED_ADMIN_FAILURE: 'NOTIFIED_ADMIN_FAILURE',

    CHECK_LIST_CUSTOMER_SUCCESS: 'CHECK_LIST_CUSTOMER_SUCCESS',
    CHECK_LIST_CUSTOMER_FAILURE: 'CHECK_LIST_CUSTOMER_FAILURE',

    CHECK_ADD_CUSTOMER_SUCCESS: 'CHECK_ADD_CUSTOMER_SUCCESS',
    CHECK_ADD_CUSTOMER_FAILURE: 'CHECK_ADD_CUSTOMER_FAILURE',

    CHECK_UPDATE_CUSTOMER_SUCCESS: 'CHECK_UPDATE_CUSTOMER_SUCCESS',
    CHECK_UPDATE_CUSTOMER_FAILURE: 'CHECK_UPDATE_CUSTOMER_FAILURE',

    ADD_SELECTED_CUSTOMER: 'ADD_SELECTED_CUSTOMER_SUCCESS',
    REMOVE_SELECTED_CUSTOMER: 'REMOVE_SELECTED_CUSTOMER_SUCCESS',
    GET_LOGO: 'GET_LOGO',

    REMOVE_ADD_UPDATE_MESSAGE: 'REMOVE_ADD_UPDATE_MESSAGE',
    APPROVER_INITIATOR_FETCH_SUCCESS: 'APPROVER_INITIATOR_FETCH_SUCCESS',
    APPROVER_INITIATOR_FETCH_FAILURE: 'APPROVER_INITIATOR_FETCH_FAILURE',

    APPROVE_CUSTOMER_SUCCESS: 'APPROVE_CUSTOMER_SUCCESS',
    APPROVE_CUSTOMER_FAILURE: 'APPROVE_CUSTOMER_FAILURE',

    APPROVE_DOCUMENT_SUCCESS: 'APPROVE_DOCUMENT_SUCCESS',
    APPROVE_DOCUMENT_FAILURE: 'APPROVE_DOCUMENT_FAILURE',

    CHECK_ADD_DOCUMENT_FAILURE: 'CHECK_ADD_DOCUMENT_FAILURE',
    CHECK_ADD_DOCUMENT_SUCCESS: 'CHECK_ADD_DOCUMENT_SUCCESS',
    CHECK_DOCUMENT_DETAILS_SUCCESS: 'CHECK_DOCUMENT_DETAILS_SUCCESS',
    CHECK_DOCUMENT_DETAILS_FAILURE: 'CHECK_DOCUMENT_DETAILS_FAILURE',
    CHECK_LIST_DOCUMENTS_SUCCESS: 'CHECK_LIST_DOCUMENTS_SUCCESS',
    CHECK_LIST_DOCUMENTS_FAILURE: 'CHECK_LIST_DOCUMENTS_FAILURE',
    DOCUMENT_REMOVE_ADD_MESSAGE: 'DOCUMENT_REMOVE_ADD_MESSAGE',
    DOCUMENT_PARENT_BY_ID_SUCCESS: 'DOCUMENT_PARENT_BY_ID_SUCCESS',
    DOCUMENT_PARENT_BY_ID_ERROR: 'DOCUMENT_PARENT_BY_ID_ERROR',

    CHECK_LIST_PARENT_DOCUMENTS_SUCCESS: 'CHECK_LIST_PARENT_DOCUMENTS_SUCCESS',
    CHECK_LIST_PARENT_DOCUMENTS_FAILURE: 'CHECK_LIST_PARENT_DOCUMENTS_FAILURE',

    CHECK_UPLOAD_DOCUMENTS_SUCCESS: 'CHECK_UPLOAD_DOCUMENTS_SUCCESS',
    CHECK_UPLOAD_DOCUMENTS_FAILURE: 'CHECK_UPLOAD_DOCUMENTS_FAILURE',
    CHECK_ADD_DOCUMENTS_SUCCESS: 'CHECK_ADD_DOCUMENTS_SUCCESS',
    CHECK_ADD_DOCUMENTS_FAILURE: 'CHECK_ADD_DOCUMENTS_FAILURE',

    CONTACT_US_SUCCESS: 'CONTACT_US_SUCCESS',
    CONTACT_US_FAILURE: 'CONTACT_US_FAILURE',
}