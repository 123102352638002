import React from "react";
import { API } from "../_services";
import { CustomerDetailsModal } from "../_components/CustomerDetailsModal";

import "../LoginPage/login.css";
import { Link } from 'react-router-dom';
import { TermsAndCondition } from "../_components/TermsAndCondition";

export class DocSearch extends React.Component {
  constructor() {
    super();
    this.state = {
      error: "",
      showPopup: false,
      selectedCompany: "",
      companyList: [],
      companyLogoMap: {},
      customerLogoUrl: "",
      logo: "",
      showTermsAndCondition: false,
    };
    this.togglePopup = this.togglePopup.bind(this);
    this.toggleTermsAndCondition = this.toggleTermsAndCondition.bind(this);
  }

  toggleTermsAndCondition(){
    this.setState({
      showTermsAndCondition: !this.state.showTermsAndCondition,
    });
  }
  async componentDidMount() {
    let res1 = await API.getCompanyListModule2();
    let customerName = this.props.match.params.customerName;
    let result = await API.getParentLogoForCustomer(customerName);
    let companyList = result.data.list;
    let logo = result.data.customerLogoUrl;
    this.setState({ companyList: companyList, logo: logo, customerName: customerName });
  }
  togglePopup(selectedComapany) {
    selectedComapany.customerName = this.state.customerName;
    selectedComapany.logo = this.state.logo;
    this.setState({
      showPopup: !this.state.showPopup,
      selectedComapany: selectedComapany
    });
  }
  getLogoDetails(id, param) {
    try {
      return this.state.companyLogoMap[id][param];
    } catch (error) {
      return {};
    }
  }

  render() {
    return (
      <div className="container-fluid">
        {this.state.showPopup ? (
          <CustomerDetailsModal
            text="Close Me"
            closePopup={this.togglePopup.bind(this)}
            companyData={this.state.selectedComapany}
            custLogo={this.state.logo}
          />
        ) : null}
        {this.state.showTermsAndCondition ? (
          <TermsAndCondition
            text="Close Me"
            closePopup={this.toggleTermsAndCondition.bind(this)}
          />
        ) : null}
        <div className="container">
          <div className="head pt-3 text-center">
            <ul className="list-unstyled list-inline">
              <li className="list-inline-item">
                <a href="#">
                  <img src={this.state.logo} className="img-fluid-med logo" />
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="content1">
          <div className="container text-left">
            <div className="row d-flex justify-content-center mt-4">
              <div className="col-md-12 col-lg-11">
                <div className="search-result">
                  <div className="row">
                    <div className="col-12">
                      <h5 className="text-black">Select Product</h5>
                      <hr />
                    </div>
                    {this.state.companyList.map((company) => (
                      <div className="col-md-4 col-lg-3">
                        <div className="company-list">
                          <a onClick={() => this.togglePopup(company)}>
                            {/* <img src="" className="img-fluid logo"/> */}
                            <img src={company.logoUrl} className="img-fluid-small logo" />
                            <h6 className="mt-2">{company.productFamilyName}</h6>
                          </a>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer className="footer text-center pt-3 pb-3s">
          <div className="container text-center">
            <p className="mb-0">
              © 2021 Powered by Shedpaper.{" "}
              <Link to='/contactus' style={{ color: 'black', textDecoration: 'none' }}>
                <a className="text-primary">
                  Contact
                </a>
              </Link>
              <a onClick={() => this.toggleTermsAndCondition()} className="text-primary TC">
                Terms And Condition
              </a>
            </p>
          </div>
        </footer>
      </div>
    );
  }
}
