import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import { customers } from './customer.reducer';
import { documents } from './document.reducer';

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  customers,
  alert,
  documents,
});

export default rootReducer;