import React from "react";
import { API } from "../_services";
import Select from "react-dropdown-select";
import { SearchResult } from "../CustomerDocSearch/SearchResult";
import { connect } from "react-redux";
import { Checkbox } from '@material-ui/core';

class DocumentSearch extends React.Component {
  constructor() {
    super();
    this.state = {
      error: "",
      productName: "",
      searchResult: [],
      keyword: '',
      changeOrderNumber: '',
      showSearchResult: false,
      selectedCustomer: '',
      customerList: [],
      dataTick: 0,
      isArchieveSearch: false,
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.updateArchieveSearch = this.updateArchieveSearch.bind(this);
  }

  componentDidMount() {
    let customerData =
      typeof this.props.customerList.items === "object" && this.props.customerList.items
        ? this.props.customerList.items
        : [];
    let customerList = [];
    if (customerData) {
      customerData.map((v) => {
        customerList.push({ value: v.id, name: v.customerName, label: v.customerName });
      });
    }

    this.setState({
      customerList: customerList
    });
  }

  updateArchieveSearch(e) {
    this.setState({ isArchieveSearch: this.state.isArchieveSearch ? false : true });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(event) {
    event.preventDefault();
    let formData = {
      keyword: this.state.keyword,
      changeOrderNumber: this.state.changeOrderNumber,
      productName: this.state.productName,
      customerName: this.state.selectedCustomer,
      isArchieveSearch: this.state.isArchieveSearch
    };
    API.internalSearchDocuments(formData)
      .then((result) => {
        this.setState({
          searchResult: result.data.list,
          showSearchResult: true,
          dataTick: (this.state.dataTick + 1)
        });
      })
      .catch((err) => {
        this.setState({ error: "No Data Found" });
      });
  }

  selectedCustomerValue(values) {
    this.setState({
      selectedCustomer: values[0].name
    });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="content1">
          <div className="container text-center">
            <div className="row d-flex justify-content-center mt-4">
              <div className="col-md-12 col-lg-8">
                <div className="box001 text-left">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Search field </label>
                          <input
                            type="text"
                            name="keyword"
                            id=""
                            placeholder="Keywords"
                            value={this.state.keyword}
                            onChange={this.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>ChangeOrder Number </label>
                          <input
                            type="text"
                            name="changeOrderNumber"
                            id=""
                            placeholder=""
                            value={this.state.changeOrderNumber}
                            onChange={this.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Product Name</label>
                          <input
                            type="text"
                            name="productName"
                            id=""
                            placeholder=""
                            value={this.state.productName}
                            onChange={this.handleChange}
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <label>Customer Name</label>
                          <Select
                            className="form-control"
                            options={this.state.customerList}
                            onChange={(e) => this.selectedCustomerValue(e)}
                          />
                          <Checkbox style={{ marginTop: "3px", padding: 0}} color='primary' name='Archieve Search' checked={this.state.isArchieveSearch == true ? true : false} onChange={this.updateArchieveSearch}></Checkbox>
                          <label style={{ marginTop: "7px", paddingLeft: "0px" }}>Archieve Search</label><br />
                        </div>
                      </div>
                      <div className="col-md-12 mt-4 text-center">
                        <button
                          className={
                            "btn btn-block btn-gradient-primary btn-md fit-content font-weight-medium auth-form-btn m-auto"
                          }
                          onClick={this.handleSubmit}>
                          Submit
                          </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        { this.state.showSearchResult &&
          <div>
            <SearchResult
              searchMode='internal'
              custLogo={this.state.custlogo}
              prodLogo={this.state.prodLogo}
              searchData={this.state.searchData}
              email={this.state.encryptedEmail}
              searchResult={this.state.searchResult}
              key={this.state.dataTick}
            ></SearchResult>
          </div>
        }

        <div className="footer text-center pt-3 pb-3s" style={{ position: "fixed" }}>
          <div className="container text-center">
            <p className="mb-0">
              © 2021 Powered by Shedpaper.{" "}
              <a href="#" className="text-primary">
                Contact
              </a>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  customerList: state.customers,
});

export default connect(mapStateToProps)(DocumentSearch);
